<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="member">
      <span>姓名：{{ member.memberName }}</span>
      <!-- <span>报告生成时间：</span> -->
    </div>
    <!-- 非循环贷账户信息 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="5">非循环贷账户信息</td>
      </tr>
      <tr>
        <td>机构数</td>
        <td>账户数</td>
        <td>授信总额</td>
        <td>贷款余额</td>
        <td>最近6个月平均应还款</td>
      </tr>
      <tr>
        <td>{{ reportInfo.c0003 }}&nbsp;</td>
        <td>{{ reportInfo.c0002 }}</td>
        <td>{{ reportInfo.c0001 }}</td>
        <td>{{ reportInfo.c0005 }}</td>
        <td>{{ reportInfo.c0004 }}</td>
      </tr>
    </table>

    <!-- 循环贷账户信息 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="5">循环贷账户信息</td>
      </tr>
      <tr>
        <td>机构数</td>
        <td>账户数</td>
        <td>授信总额</td>
        <td>贷款余额</td>
        <td>最近6个月平均应还款</td>
      </tr>
      <tr>
        <td>{{ reportInfo.c0012 }}&nbsp;</td>
        <td>{{ reportInfo.c0011 }}</td>
        <td>{{ reportInfo.c0015 }}</td>
        <td>{{ reportInfo.c0014 }}</td>
        <td>{{ reportInfo.c0013 }}</td>
      </tr>
    </table>

    <!-- 循环额度分账户信息 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="5">循环额度分账户信息</td>
      </tr>
      <tr>
        <td>机构数</td>
        <td>账户数</td>
        <td>授信总额</td>
        <td>贷款余额</td>
        <td>最近6个月平均应还款</td>
      </tr>
      <tr>
        <td>{{ reportInfo.c0009 }}&nbsp;</td>
        <td>{{ reportInfo.c0007 }}</td>
        <td>{{ reportInfo.c0008 }}</td>
        <td>{{ reportInfo.c0010 }}</td>
        <td>{{ reportInfo.c0006 }}</td>
      </tr>
    </table>

    <!-- 账户信息小结 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="4">账户信息小结</td>
      </tr>
      <tr>
        <td>未结清贷账户数</td>
        <td>房贷账户数</td>
        <td>未结清房贷账户总金额</td>
        <td>除房贷以外未结清抵押/质押类贷款账户数</td>
      </tr>
      <tr>
        <td>{{ reportInfo.xs001 }}&nbsp;</td>
        <td>{{ reportInfo.xs002 }}</td>
        <td>{{ reportInfo.xs003 }}</td>
        <td>{{ reportInfo.xs004 }}</td>
      </tr>
      <tr>
        <td>经营类贷账户数</td>
        <td colspan="2">未结清经营类贷款账户余额</td>
        <td>除房贷以外未结清抵押/质押类贷款余额</td>
      </tr>
      <tr>
        <td>{{ reportInfo.xs006 }}&nbsp;</td>
        <td colspan="2">{{ reportInfo.xs007 }}</td>
        <td>{{ reportInfo.xs005 }}</td>
      </tr>
      <tr>
        <td style="width:50%" colspan="2">近一个月要到期贷款账户数</td>
        <td colspan="2">近一个月要到期贷款账户余额</td>
      </tr>
      <tr>
        <td colspan="2">{{ reportInfo.xs008 }}&nbsp;</td>
        <td colspan="2">{{ reportInfo.xs009 }}</td>
      </tr>
    </table>

    <!-- 贷记卡账户信息 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="5">贷记卡账户信息</td>
      </tr>
      <tr>
        <td>发卡机构数</td>
        <td>账户数</td>
        <td>授信总额</td>
        <td>已使用额度</td>
        <td>额度使用率（总）</td>
      </tr>
      <tr>
        <td>{{ reportInfo.c0022 }}&nbsp;</td>
        <td>{{ reportInfo.c0020 }}</td>
        <td>{{ reportInfo.c0018 }}</td>
        <td>{{ reportInfo.c0017 }}</td>
        <td>{{ reportInfo.xs010 }}</td>
      </tr>
      <tr>
        <td>额度使用率（单张最高）</td>
        <td colspan="4"></td>
      </tr>
      <tr>
        <td>{{ reportInfo.xs011 }}&nbsp;</td>
        <td colspan="4"></td>
      </tr>
    </table>

    <!-- 对外担保 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="3">对外担保</td>
      </tr>
      <tr>
        <td>是否存在对外担保</td>
        <td>担保金额</td>
        <td>剩余担保金额</td>
      </tr>
      <tr>
        <td>{{ reportInfo.xs012 }}&nbsp;</td>
        <td>{{ reportInfo.xs013 }}</td>
        <td>{{ reportInfo.xs014 }}</td>
      </tr>
    </table>

    <!-- 查询记录 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="3">查询记录</td>
      </tr>
      <tr>
        <td>近六个月贷款、信用卡、融资审批查询次数</td>
        <td>近三个月贷款、信用卡、融资审批查询次数</td>
        <td>近一个月本人查询次数</td>
      </tr>
      <tr>
        <td>{{ reportInfo.j0021 }}&nbsp;</td>
        <td>{{ reportInfo.j0022 }}</td>
        <td>{{ reportInfo.a0017 }}</td>
      </tr>
    </table>

    <!-- 其他 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="3">其他</td>
      </tr>
      <tr>
        <td>是否有信贷记录</td>
        <td>是否存在低保救助记录</td>
        <td>是否当前逾期</td>
      </tr>
      <tr>
        <td>{{ reportInfo.j0006 }}&nbsp;</td>
        <td>{{ reportInfo.xs016 }}</td>
        <td>{{ reportInfo.xs015 }}</td>
      </tr>
    </table>

    <!-- 模型得分 -->
    <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="2">模型得分&征信结果</td>
      </tr>
      <tr>
        <td>模型得分</td>
        <td style="width:50%">{{ reportInfo.modelScore }}</td>
      </tr>
      <tr>
        <td>模型类型</td>
        <td>{{ reportInfo.modelType }}</td>
      </tr>
      <tr>
        <td>征信规则结果</td>
        <td>{{ reportInfo.pscore }}</td>
      </tr>
      <tr>
        <td>拒绝原因</td>
        <td>{{ reportInfo.preason }}</td>
      </tr>
      <!-- <tr>
        <td colspan="2">
          模型得分：1.白户评分卡，指的是无贷款记录和信用卡记录的客群；2.少信用记录评分卡，指的是贷款记录数+信用卡记录数 &lt;=
          2的客群；3.多信用记录评分卡，指的是贷款记录数+信用卡记录数 &gt;2 的客群。
        </td>
      </tr> -->
    </table>

    <!-- 征信规则结果 -->
    <!-- <table border="1px" style="width:100%;border-color:rgb(179 182 187);">
      <tr class="bg title">
        <td colspan="4">征信规则结果</td>
      </tr>
      <tr>
        <td></td>
        <td>P1</td>
        <td>P2</td>
        <td>P3</td>
      </tr>
      <tr>
        <td>近24个月贷款还款记录中连续逾期期数</td>
        <td>{{ reportInfo.p1 }}</td>
        <td>{{ reportInfo.p2 }}</td>
        <td>{{ reportInfo.p3 }}</td>
      </tr>
      <tr>
        <td>近24个月贷记卡还款记录中连续逾期期数</td>
        <td>出现3及以上，拒绝</td>
        <td>出现3及以上，拒绝</td>
        <td>出现4及以上，拒绝</td>
      </tr>
      <tr>
        <td>近24个月还款记录中全部贷款和贷记卡累计逾期期数</td>
        <td>&gt;12，拒绝</td>
        <td>&gt;12，拒绝</td>
        <td>&gt;20，拒绝</td>
      </tr>
      <tr>
        <td>任一账户是否存在“资产处置” “担保人代还”</td>
        <td>是，拒绝</td>
        <td>是，拒绝</td>
        <td>是，拒绝</td>
      </tr>
      <tr>
        <td>任一账户五级分类是否存在“次级” “可疑”</td>
        <td>是，拒绝</td>
        <td>是，拒绝</td>
        <td>是，拒绝</td>
      </tr>
      <tr>
        <td>近12个月单笔账户中累计逾期期数</td>
        <td>>6，拒绝</td>
        <td>/</td>
        <td>/</td>
      </tr>
      <tr>
        <td>近24个月单笔贷款累计逾期期数</td>
        <td>/</td>
        <td>≥8，拒绝</td>
        <td>≥6，拒绝</td>
      </tr>
      <tr>
        <td>近24个月单张贷记卡累计逾期期数</td>
        <td>/</td>
        <td>≥8，拒绝</td>
        <td>≥6，拒绝</td>
      </tr>
      <tr>
        <td>近6个月还款记录中全部贷款和贷记卡连续逾期期数</td>
        <td>/</td>
        <td>M2≥2次，拒绝</td>
        <td>/</td>
      </tr>
    </table> -->

    <div style="height:50px;"></div>
  </div>
</template>

<script>
import { getCreditReportAPI } from './api'
export default {
  name: 'CreditReport',

  data() {
    return {
      reportInfo: {}
    }
  },
  computed: {
    member() {
      let obj = {}
      if (this.reportInfo.member) {
        obj = this.reportInfo.member
      }
      return obj
    }
  },
  mounted() {
    this.getCreditReport()
  },

  methods: {
    async getCreditReport() {
      this.reportInfo = await getCreditReportAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  table {
    margin-bottom: 30px;
    thead {
      text-align: center;
    }
    tr {
      td {
        text-align: center;
        padding: 5px 0;
      }
      &:hover {
        background-color: #fafafa;
      }
    }
    .bg {
      background-color: #fafafa;
    }
    .title {
      font-weight: 700;
    }
  }
  .member {
    margin-bottom: 10px;
    span {
      margin-right: 20px;
      // font-weight: 600;
    }
  }
}
</style>
